@font-face {
  font-family: Regular;
  src: url(./assets/ChicagoFLF.ttf);
}

:root {
  --primary-grey: #707070;
  --primary-gold: #FBB827;
  --primary-green: #68BD4D;
  --primary-orange: #F7A35C;
  --primary-red: #E13A3E;
  --primary-purple: #A151A1;
  --primary-blue: #1B9EDB;
  --primary-light: #F8F9FA;
  --primary-ltgrey: #9C9C9D;
  --primary-font: Regular;
}

body {
  font-family: var(--primary-font) !important;
  background: var(--primary-light) !important;
  color: var(--primary-grey);
}