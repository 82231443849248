@media(max-width:768px) {
    .main-info h1 {
        font-size: 25px !important;
    }
}

.header-wrapper {
    position: relative;
    background: url(../../assets/Wallpaper-tile.png) repeat;
    background-size: 4px;
    background-position: center;
    height: 100vh;
    font-family: var(--primary-font);
}

.main-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.main-info h1 {
    color: var(--primary-gold);
    background: var(--primary-light);
    text-shadow: 1.5px 0 0 #000000, 0 -1.5px 0 #000000, 0 1.5px 0 #000000, -1.5px 0 0 #000000;
}

.main-info h2 {
    color: var(--primary-grey);
    background: var(--primary-light);
}

.btn-main-offer,
.resume {
    border: 3px solid var(--primary-gold);
    font-family: var(--primary-font);
    border-radius: 0;
    padding: 10px 12px 12px 12px;
    color: var(--primary-light);
    margin: 32px;
}

.btn-main-offer:hover,
.resume:hover {
    text-decoration: none;
    background: var(--primary-light);
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    color: var(--primary-grey);
    transition: .2s ease-in-out;
    text-shadow: none;
}

.links {
    margin-top: 40px;
}