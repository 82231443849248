.footer {
    text-decoration: none;
    background: var(--primary-light);
    color: var(--primary-ltgrey);
    text-align: center;


}

.footer a {
    text-decoration: none;
    color: var(--primary-ltgrey);
}

.footer a:hover {
    background: var(--primary-grey);
    color: var(--primary-light);
    transition: .2s ease-in-out;
    cursor: pointer;
}

.footer .links {
    font-size: 35px;
    text-align: right;
    padding: 0 5px 0 5px;
}

.links a:hover {
    background-color: none;
}